import { isEmpty } from 'lodash';

export default {
    props: {
        credentials: Object,
        registeringUser: Boolean,
        validationErrors: Object,
        onClickBack: Function,
        isMobileView: Boolean,
        onSubmitUserInformation: Function,
    },
    computed: getComputed(),
};

function getComputed(){
    return {
        disabledGettingStarted(){
            if(!isEmpty(this.credentials.first_name) && !isEmpty(this.credentials.last_name) && !isEmpty(this.credentials.email) && this.credentials.acceptance_status){
                return false;
            }
            return true;
        }
    };
}
