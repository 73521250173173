export default {
    data: () => {
        return {
            apiErrors: [],
            successMessages: [],
            isSendingEmail: false,
            smsCode: null,
            phoneNumber: null,
            validationErrors: {},
            maskOptions: {
                masked: null,
                unmasked: null,
                completed: false
            },
            timerCountEmail: null,
            timerCountSms: null,
            showNewNumberOption: false,
            requireNewNumber: false,
        };
    },
    computed: {
        user(){
            return this.$store.getters.user;
        },
        notifiedEmailVerify(){
            return this.$store.state.guest.user.notifiedEmailVerify;
        },
        loadingUser(){
            return this.$store.getters['user/isLoading'];
        },
        showEmailVerification(){
            return this.notifiedEmailVerify || !this.user.email_verified;
        },
    },
    watch: {
        timerCountEmail: {
            handler(value){
                if(value > 0){
                    setTimeout(() => {
                        this.timerCountEmail--;
                    }, 1000);
                }
            },
            immediate: true,
        },
        timerCountSms: {
            handler(value){
                if(value > 0){
                    setTimeout(() => {
                        this.timerCountSms--;
                    }, 1000);
                }
            },
            immediate: true,
        }
    },
    mounted: function(){
        this.proceedToApplication(true);
        this.getRemainsResendEmail();
        this.getRemainsResendSms();
    },
    methods: {
        getRemainsResendEmail(){
            const options = { hour12: true, timeZone: 'UTC' };
            const date = new Date(new Date(this.user.verification_requested_at)
                .toLocaleString('en-US', options));
            const now = new Date();
            const dateFormatted = new Date(now.toLocaleString('en-US', options));
            const currentDate = this.getSecondsBetweenDates(dateFormatted, date);

            this.timerCountEmail = 60 - currentDate;
        },
        getRemainsResendSms(){
            const options = { hour12: true, timeZone: 'UTC' };
            const date = new Date(new Date(this.user.phone_verification_requested_at)
                .toLocaleString('en-US', options));
            const now = new Date();
            const dateFormatted = new Date(now.toLocaleString('en-US', options));
            const currentDate = this.getSecondsBetweenDates(dateFormatted, date);

            this.timerCountSms = 60 - currentDate;
        },
        getSecondsBetweenDates(startDate, endDate){
            return Math.abs(startDate - endDate) / 1000;
        },
        associatePhoneNumber(){
            this.isSendingEmail = true;

            Vue.appApi()
                .authorized()
                .user()
                .associatePhoneNumber(this.maskOptions.unmasked)
                .then((response) => {
                    this.$store.commit('user/SET_USER', response.data);
                    this.successMessages.push('Phone number associated to your account.');
                    this.getRemainsResendSms();
                    this.requireNewNumber = false;
                })
                .catch(this.displayApiErrors)
                .finally(() => {
                    this.isSendingEmail = false;
                });
        },
        resendVerificationEmail(){
            this.isSendingEmail = true;

            Vue.appApi()
                .authorized()
                .user()
                .resendVerificationEmail()
                .then((response) => {
                    this.$store.commit('user/SET_USER', response.data);
                    this.successMessages.push('Email sent, please allow a few minutes for delivery.');
                    this.getRemainsResendEmail();
                })
                .catch(this.displayApiErrors)
                .finally(() => {
                    this.isSendingEmail = false;
                });
        },
        resendVerificationPhone(){
            this.isSendingEmail = true;

            Vue.appApi().authorized()
                .user()
                .resendVerificationPhone()
                .then((response) => {
                    this.$store.commit('user/SET_USER', response.data);
                    this.successMessages.push('SMS sent, please allow a few minutes for delivery.');
                    this.getRemainsResendSms();
                })
                .catch(this.displayApiErrors)
                .finally(() => {
                    this.isSendingEmail = false;
                });
        },
        verifyCode(){
            this.isSendingEmail = true;

            Vue.appApi()
                .authorized()
                .user()
                .verifySmsCode(this.smsCode)
                .then((response) => {
                    this.$store.commit('user/SET_USER', response.data);
                    this.$router.push({ name: 'business-priorities' });
                })
                .catch(err => {
                    this.showNewNumberOption = true;
                    this.displayApiErrors(err);
                })
                .finally(() => {
                    this.isSendingEmail = false;
                });
        },
        displayApiErrors({ appMessage, ...rest }){
            if(appMessage){
                this.apiErrors = [appMessage];
            }

            this.validationErrors = rest.data.errors || {};
        },
        proceedToApplication(isInitialLoad = false){
            const vm = this;
            vm.$store.dispatch('user/GET_USER', true).then(handleRedirect);

            function handleRedirect(){
                const userVerified = vm.user && vm.user.email_verified && vm.user.phone_verified;
                const userIsSubscriber = !!vm.user.current_account.status;
                if(userVerified && !userIsSubscriber){
                    vm.$router.push({ name: 'subscription-required' });
                } else {
                    const destination = userIsSubscriber
                        ? { name: 'dashboard' }
                        : vm.$store.state.tourWalkthrough.tourLinks[0];

                    vm.redirectIfVerified(destination, isInitialLoad);
                }
            }
        },
        redirectIfVerified(destination, isInitialLoad){
            if(this.user && this.user.email_verified && this.user.phone_verified){
                this.$router.push(destination);
            } else if(!isInitialLoad){
                this.apiErrors.push(
                    'We were unable to confirm your email address or your phone number. Please make sure both are verified.'
                );
            }
        }
    }
};
